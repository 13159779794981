import React from 'react'

import {
  Divider,
  Grid,
  Header,
  Segment,
  Icon
} from 'semantic-ui-react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomepageLayout from '../components/HomepageLayout'
import FluidImageCelular from '../components/images/FluidImageCelular'

const styleDivider = {
  margin: '3em 0em',
  textTransform: 'uppercase',
  color:'gray'
}

const Produtos = () => (
  <Segment style={{
      padding: '8em 0em'
    }} vertical="vertical">
    <Grid container="container" stackable="stackable" verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column floated='left' width={6}>
          <FluidImageCelular />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h3' style={{
              fontSize: '2em'
            }}>
            Produtos
          </Header>
          <p style={{
              fontSize: '1.33em'
            }}>
            Desenvolvemos produtos sob demanda, software personalizado pra atender suas necessidades.
          </p>
          <p style={{
              fontSize: '1.33em'
            }}>
            Passe as especificações técnicas e de negócios, combine prazos e custos e acompanhe o cronograma de desenvolvimento e entrega.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#apps' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='mobile'/> APPs
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Aplicativos Android e IOS
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Aplicativos modernos e rápidos que integrem aos seus produtos atuais ou que sejam produtos novos desenvlvidos so começo ao fim.
        </p>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Aplicativos Web Progressivos
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Aplicativos que funcionam na Web e que podem ser facilmente instalados em dispositivos moveis diretamente.
        </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#bots' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='cogs'/> BOTS
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Automatize tarefas utilizando softwares robôs
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Criamos robôs para atendimento on-line, indicação de produtos, coleta de dados e outras tarefas.
        </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#ia' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='book'/> Inteligencia Artificial
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Aplique técnicas avançadas pra obter melhores resultados
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Utilizando técnicas de Inteligencia artificial e aprendizagem de máquina podemos criar sistemas que melhoram com as interações com o usuário, sistemas que tomam decisões ou que mineram informações em seus dados.
        </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#ecomerce' className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='shop cart'/> Lojas Virtuais
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Comercialize seus produtos pela internet
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Crie uma loja Virtual personalizada para seu negócio, com busca, categorização, carrinho de compras e integrações para recebimento.
        </p>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
        <Divider as='a' href='#integradores'  className='header' horizontal="horizontal" style={styleDivider}>
           <Icon className='sync'/> Integração
        </Divider>
        <Header as='h3' style={{
            fontSize: '2em'
          }}>
          Leve a informação aonde ela precisa chegar!
        </Header>
        <p style={{
            fontSize: '1.33em'
          }}>
          Podemos criar uma solução personalizada que integre informações de varias fontes e sistemas, Podemos obter informações de campanhas feitas em redes sociais como o facebook, estas informações podem ser coletadas, tratadas e enviadas de maneira formatada ou até mesmo disponibilziadas em um software de BI ou em forma de E-Mails e relatórios.
        </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

const ProdutosPage = () => (
  <Layout>
    <SEO title="Produtos" lang="pt" keywords={['software', 'tecnology', 'web', 'b2b', 'e-comerce']}  />
    <HomepageLayout showLogo={false} page='produtos'>
      <Produtos />
    </HomepageLayout>
  </Layout>
)

export default ProdutosPage
